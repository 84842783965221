import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../Home/home.css";
import WOW from "wowjs";
import moment from "moment";

function PreviousCourses({ courseList }) {
  const navigate = useNavigate();

  useEffect(() => {
    new WOW.WOW().init();
  }, [courseList]);

  return (
    <>
      {/* Medical Service Section Start */}
      <div id="course" className="medical-services recent-post courselist">
        <div className="blog-area ptb-150">
          <div className="container">
            <div className="row justify-content-center" data-cues="slideInUp">
              <div className="col-12">
                <div className="medical-service-heading mb-md-4">
                  <div className="section-title text-center">
                    {/* <h3 className="wow fadeInUp">Previous Courses</h3> */}
                    <h2 className="text-anime-style-3">
                      Our Previous Courses
                    </h2>
                  </div>
                </div>
              </div>

              {courseList.filter((data) => (data?.isAvailable && new Date().toISOString().split('T')[0] > new Date(data?.courseEndDate).toISOString().split('T')[0])).length > 0 ? (
                courseList.filter((data) => (data?.isAvailable && new Date().toISOString().split('T')[0] > new Date(data?.courseEndDate).toISOString().split('T')[0])).map((item, i) => {
                  return (
                    <div
                      className="col-xl-4 col-lg-4 col-md-6"
                      key={i}
                      onClick={() =>
                        navigate(`/courses-details?id=${item._id}`)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div
                        className="blog-card extra-gap post-item wow fadeInUp"
                        data-wow-delay="0.25s"
                      >
                        <div className="p-4 servicebordercladd">
                          <div className="blog-image">
                            <span className="post-featured-image">
                              <figure className="image-anime serviceblog">
                                {item?.courseImg && item?.courseImg[0] && (
                                  <img
                                    alt={item?.courseImg[0]}
                                    src={
                                      process.env.REACT_APP_IMAGE_API_URL +
                                      item?.courseImg[0]
                                    }
                                  />
                                )}
                              </figure>
                            </span>
                            <div className="user">
                              <figure
                                className="image-anime"
                                style={{ height: "100%", width: "100%" }}
                              >
                                <img
                                  alt={item.speakerImg[0]}
                                  src={
                                    process.env.REACT_APP_IMAGE_API_URL +
                                    item.speakerImg[0]
                                  }
                                />
                              </figure>
                            </div>
                          </div>
                          <div
                            className="blog-content"
                            style={{ backgroundColor: "transparent" }}
                          >
                            <span className="tag-btn">
                              {item?.category?.name}
                            </span>
                            <div className="post-item-body">
                              <h2>{item?.name}</h2>
                              {/* <div className="text-truncate">
                                  This advanced course is designed for dental
                                  professionals who aspire to excel in the field
                                  of aesthetic dentistry. It combines
                                  theoretical knowledge with practical skills,
                                  enabling participants to deliver high
                                </div> */}
                              {/* <p
                                  dangerouslySetInnerHTML={{
                                    __html: item?.description,
                                  }}
                                /> */}
                              <ul className="list mt-3 mb-0">
                                <li className="dateli">
                                  <strong>
                                    <i className="fa fa-calendar-days"></i>
                                    &nbsp;{moment(item?.courseStartDate).format('DD-MM-YYYY')} to{" "}
                                    {moment(item?.courseEndDate).format('DD-MM-YYYY')}
                                  </strong>
                                </li>
                                <li className="priceli">
                                  Price :
                                  <strong>
                                    {+item?.price === 0 ? "Free" : "Paid"}
                                  </strong>
                                </li>
                              </ul>
                              <span
                                className="btn-defaultnew learnbutton wow fadeInUp col-xl-6 col-md-6 p-2"
                                data-wow-delay="0.5s"
                              >
                                Learn more
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className="d-flex justify-content-center">
                  No courses available at the moment. Please check back soon for
                  new opportunities to learn and grow!
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Medical Service Section End */}
    </>
  );
}

export default PreviousCourses;

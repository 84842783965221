import React, { useEffect, useState } from "react";
import Header from "../../commonPages/Header";
import Footer from "../../commonPages/Footer";
import { getRequest } from "../../../helper/axiosClient";
import { BLOG_DETAILS } from "../../constant/apiEndPoint";
import { useSearchParams } from "react-router-dom";
import { errorNotification } from "../../../helper/notification";
import Banner from "../CourseDetails/Banner";
import Loader from "../../../helper/Loader";

const BlogDetails = () => {
  const [courseDetails, setCourseDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const couresId = searchParams.get("id");

  const getCourseDetails = async (Id) => {
    try {
      const params = { id: Id };
      const res = await getRequest(BLOG_DETAILS, params);
      if (res?.data?.status === 1) {
        setCourseDetails(res?.data?.data[0]);
      }
      if (res.type === 2) {
        errorNotification(res.errormessage);
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (couresId) {
      setLoading(true);
      getCourseDetails(couresId);
    }
  }, []);

  const decodeHtmlEntities = (str) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = str;
    return txt.value;
  };

  return (
    <>
      {/* Main content Start */}
      <div className="main-content">
        <Header />
        <Banner courseDetails={courseDetails} />

        <>
          {loading ? (
            <Loader />
          ) : (
            <div className="page-project-single">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8">
                    {/* Project Content Start */}
                    <div className="project-content">
                      {/* Project Feature Image Start */}
                      <div
                        className="project-feature-image wow fadeInUp"
                        data-wow-delay="0.25s"
                      >
                        <figure className="image-anime">
                          {courseDetails?.blogImage &&
                            courseDetails?.blogImage[0] && (
                              <img
                                alt={courseDetails?.blogImage[0]}
                                src={
                                  process.env.REACT_APP_IMAGE_API_URL +
                                  courseDetails?.blogImage[0]
                                }
                              />
                            )}
                        </figure>
                      </div>
                      {/* Project Feature Image End */}
                      {/* Project Entry Start */}
                      <div className="project-entry">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: decodeHtmlEntities(
                              courseDetails?.description || ""
                            ),
                          }}
                        />
                      </div>
                    </div>
                    {/* Project Content End */}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>

      </div>
      <Footer />
      {/* Main content End */}
    </>
  );
};

export default BlogDetails;

import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import SplitType from "split-type";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { dateWithDay } from "../../../helper/dateFormate";
import StudentRegisterPopup from "../Modal/StudentRegisterPopup";
import { Parallax } from "react-scroll-parallax";
import { getCurrentUserFromLocalStorage } from "../../../localStorage/setCurrentUser";

function Upcomingcourse({ courseList }) {
  const elementRef = useRef(null);

  useEffect(() => {
    if (elementRef.current) {
      const element = elementRef.current;

      if (element.animation) {
        element.animation.progress(1).kill();
      }

      let split = new SplitType(element, { types: "lines,words,chars" });
      gsap.set(element, { perspective: 400 });

      gsap.set(split.chars, {
        opacity: 0,
        x: "50",
      });

      element.animation = gsap.to(split.chars, {
        scrollTrigger: { trigger: element, start: "top 90%" },
        x: "0",
        y: "0",
        rotateX: "0",
        opacity: 1,
        duration: 1,
        ease: "back.out",
        stagger: 0.02,
      });
    }
  }, [courseList]);

  const navigate = useNavigate();
  const userDetails = getCurrentUserFromLocalStorage();
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [courseId, setCourseId] = useState(null);
  const [courseDetails, setCourseDetails] = useState([]);
  const [courseStatusForStudent, setCourseStatusForStudent] = useState("");

  const handleBook = (item) => {
    const token = Cookies.get("token");
    if (token) {
      setCourseId(item?.id);
      setCourseDetails(item);
      if (userDetails && item?.id) {
        const courseStatus = userDetails?.courses?.find(
          (item) => item.courseId === item?.id
        )?.status;
        setCourseStatusForStudent(courseStatus);
      }

      setIsOpenPopup(true);
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      <div
        id="upcomingcourse"
        className="home-contact-form upcomingcoursesection"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/* Section Title Start */}
              <div className="section-title p-0">
                <h3 className="wow fadeInUp">
                  Courses coming soon to Sage Academy
                </h3>
                <h2 className="text-anime-style-2" ref={elementRef}>
                  Upcoming Courses
                </h2>
              </div>
              {/* Section Title End */}
              {/* Start Services Area */}
              <div className="services-area without-bg-color ptb-150">
                <div className="container">
                  <div
                    className="row justify-content-center"
                    data-cues="slideInUp"
                  >
                    {courseList.filter((data) => (data?.isAvailable && new Date().toISOString().split('T')[0] < new Date(data?.courseStartDate).toISOString().split('T')[0])).length > 0 ? (
                      courseList.filter((data) => (data?.isAvailable && new Date().toISOString().split('T')[0] < new Date(data?.courseStartDate).toISOString().split('T')[0])).map((item, i) => {
                        return (
                          <div
                            className="col-xl-6 col-lg-6 col-md-12 wow fadeInUp"
                            data-wow-delay="0.25s"
                            key={i}
                          >
                            <div className="services-item">
                              <div className="row justify-content-center align-items-center">
                                <div className="col-lg-5 col-md-5">
                                  <div className="service-image">
                                    <figure className="image-anime">
                                      {item?.courseImg &&
                                        item?.courseImg[0] && (
                                          <img
                                            alt={item?.courseImg[0]}
                                            src={
                                              process.env
                                                .REACT_APP_IMAGE_API_URL +
                                              item?.courseImg[0]
                                            }
                                          />
                                        )}
                                    </figure>
                                  </div>
                                </div>
                                <div className="col-lg-7 col-md-7">
                                  <div className="service-content">
                                    <h3
                                      className="text-anime-style-3"
                                      ref={elementRef}
                                    >
                                      {item?.name}
                                    </h3>
                                    <p className="wow fadeInUp subtextlead my-2">
                                      We take pride in offering course start
                                      from date{" "}
                                      <strong>
                                        {dateWithDay(item?.courseStartDate)}
                                      </strong>
                                    </p>
                                    <ul className="list mb-3 mt-1">
                                      <li>
                                        <strong>
                                          {item?.courseStartTime} to{" "}
                                          {item?.courseEndTime}
                                        </strong>
                                      </li>
                                      <li>
                                        Price :{" "}
                                        <strong>
                                          {+item?.price === 0
                                            ? "Free"
                                            : "Paid"}
                                        </strong>
                                      </li>
                                    </ul>
                                    <div className="row">
                                      <span
                                        className="service-btn service-btncolor col-12"
                                        onClick={() =>
                                          navigate(
                                            `/courses-details?id=${item._id}`
                                          )
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        More Info
                                      </span>
                                      {/* <span
                                        className="btn-defaultnew wow fadeInUp col-xl-6 col-md-6"
                                        data-wow-delay="0.5s"
                                        onClick={() =>
                                          handleBook(item)
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        Book Now
                                      </span> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <p className="d-flex justify-content-center text-white text-center pb-3">
                        No courses available at the moment. Please check back
                        soon for new opportunities to learn and grow!
                      </p>
                    )}
                  </div>
                </div>
              </div>
              {/* End Services Area */}
            </div>
          </div>
        </div>
        <StudentRegisterPopup
          courseId={courseId}
          isOpen={isOpenPopup}
          setIsOpenPopup={setIsOpenPopup}
          setCourseId={setCourseId}
          courseDetails={courseDetails}
          setCourseStatusForStudent={setCourseStatusForStudent}
        />
      </div>
    </>
  );
}

export default Upcomingcourse;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Cookies from "js-cookie";
import { successNotification } from "../../helper/notification";
import WOW from "wowjs";
import "animate.css/animate.min.css";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

function Header() {
  const handleLogout = () => {
    successNotification("Logout successfully!");
    Cookies.remove("token");
    localStorage.clear();
  };
  useEffect(() => {
    const wow = new WOW.WOW({ live: false });
    wow.init();
  }, []);

  return (
    <>
      {/* Topbar Section Start */}
      <div className="topbar wow fadeInUp">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              {/* Topbar Contact Information Start */}
              <div className="topbar-contact-info">
                <ul>
                  <li>
                    <a
                      href="https://mail.google.com/mail/?view=cm&amp;fs=1&amp;to=info@sageacademy.uk"
                      target="_blank"
                    >
                      <i className="fa-solid fa-envelope" /> info@sageacademy.uk
                    </a>
                  </li>
                  {/* <li>
                    <a href="#">
                      <i className="fa-solid fa-phone" /> +01 248 248 2481
                    </a>
                  </li> */}
                </ul>
              </div>
              {/* Topbar Contact Information End */}
            </div>
            <div className="col-md-4">
              {/* Topbar Social Links Start */}
              <div className="header-social-links">
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/sagedentaluk"
                      target="_blank"
                    >
                      <i className="fa-brands fa-facebook-f" />
                    </a>
                  </li>
                  {/* <li>
                    <a href="#">
                      <i className="fa-brands fa-twitter" />
                    </a>
                  </li> */}
                  <li>
                    <a
                      href="https://www.linkedin.com/company/sagedentaluk/about/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-linkedin-in" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/sagedentaluk/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-instagram" />
                    </a>
                  </li>
                </ul>
              </div>
              {/* Topbar Social Links End */}
            </div>
          </div>
        </div>
      </div>
      {/* Topbar Section End */}
      {/* Header Start */}
      <header className="main-header">
        <div className="header-sticky">
          <nav className="navbar navbar-expand-lg">
            <div className="container">
              {/* Logo Start */}
              <a className="navbar-brand" href="/">
                <img
                  src="assets/logo/Sage Academy Inverse Long.png"
                  alt="Logo"
                />
              </a>
              {/* Logo End */}
              {/* Main Menu start */}
              <div className="collapse navbar-collapse main-menu">
                <ul className="navbar-nav mr-auto" id="menu">
                  <li className="nav-item">
                    <Link className="nav-link" to="/" onClick={scrollToTop}>
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <HashLink className="nav-link" smooth to="/#whoweare">
                      About us
                    </HashLink>
                  </li>
                  <li className="nav-item">
                    <HashLink className="nav-link" smooth to="/#whyus">
                      Why us?
                    </HashLink>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/our-courses">
                      Course
                    </Link>
                  </li>
                  <li className="nav-item">
                    <HashLink className="nav-link" smooth to="/#upcomingcourse">
                      Upcoming Course
                    </HashLink>
                  </li>
                  <li className="nav-item">
                    <HashLink className="nav-link" smooth to="/#contact">
                      Contact Us
                    </HashLink>
                  </li>
                  {/* <li className="nav-item">
                                        <HashLink className="nav-link" smooth to="/login">
                                            Login
                                        </HashLink>
                                    </li> */}
                  <li className="nav-item">
                    {Cookies.get("token") ? (
                      <Link onClick={handleLogout} to="/">
                        <button type="submit" className="btn-default">
                          Logout
                        </button>
                      </Link>
                    ) : (
                      <Link className="" to="/login">
                        <button type="submit" className="btn-default">
                          Login
                        </button>
                      </Link>
                    )}
                  </li>
                </ul>
              </div>
              {/* Main Menu End */}
              <div className="navbar-toggle" />
            </div>
          </nav>
          <div className="responsive-menu" />
        </div>
      </header>
      {/* Header End */}
    </>
  );
}

export default Header;


import gsap from 'gsap';
import React, { useEffect, useRef } from 'react';
import SplitType from 'split-type';// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import './home.css';

// import required modules
import { Mousewheel, Pagination } from 'swiper/modules';

function WhyChooseUs() {

  const elementRef = useRef(null);

  useEffect(() => {
    if (elementRef.current) {
      const element = elementRef.current;

      if (element.animation) {
        element.animation.progress(1).kill();
      }

      let split = new SplitType(element, { types: 'lines,words,chars' });
      gsap.set(element, { perspective: 400 });

      gsap.set(split.chars, {
        opacity: 0,
        x: "50",
      });

      element.animation = gsap.to(split.chars, {
        scrollTrigger: { trigger: element, start: "top 90%" },
        x: "0",
        y: "0",
        rotateX: "0",
        opacity: 1,
        duration: 1,
        ease: "back.out",
        stagger: 0.02,
      });
    }
  }, []);

  return (
    <>
      {/* Testimonial Section Satrt */}
      <div className="clients-testimonials ourclient whychooseus">
        <div className="container-fluid">
          <div className="row section-row">
            <div className="col-lg-12">
              {/* Section Title Start */}
              <div className="section-title text-center">
                <h2 className="why" ref={elementRef}>Why Choose Us</h2>
                {/* Hero Layout 3 Content Box Start*/}
                {/* <div className="hero-body mt-3 mb-0">
                  <p>Our modern facilities are equipped with the latest technology and resources to support hands-on learning and practical skill development. From fully phantom head training room to advanced simulation centers, our facility provides students with the tools they need to succeed in today's rapidly evolving dental industry.</p>
                </div> */}
                {/* Hero Layout 3 Content Box End*/}
              </div>
              {/* Section Title End */}
            </div>
          </div>

          <div className="row ">
            <div className="col-md-12">
              {/* Our Projects Layout 2 Start */}
              <Swiper
                direction={'vertical'}
                slidesPerView={1}
                spaceBetween={30}
                mousewheel={{
                  forceToAxis: true,
                  sensitivity: 1,
                  releaseOnEdges: true,
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[Mousewheel, Pagination]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className="we-improving">
                    <div className="container">
                      <div className="row align-items-center">
                        <div className="col-lg-6">
                          {/* Improving Image Start */}
                          <div className="improving-img">
                            <figure className="image-anime ">
                              <img src="assets/images/slider-1-a.jpg" alt="" />
                            </figure>
                          </div>
                          {/* Improving Image End */}
                        </div>
                        <div className="col-lg-6">
                          {/* Improving Content Start */}
                          <div className="improving-content">
                            {/* Section Title Start */}
                            <div className="section-title">
                              <h2 className="">Excellence in Education</h2>
                            </div>
                            {/* Section Title End */}
                            {/* Improving Body Start */}
                            <div className="improving-body">
                              <p className="">SAGE Academy embodies excellence in education with high-quality, comprehensive courses. Our expert faculty provides a premier learning experience, equipping students with the skills to excel and stand out in competitive fields.</p>
                            </div>
                            {/* Improving Body End */}

                          </div>
                          {/* Improving Content End */}
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="we-improving">
                    <div className="container">
                      <div className="row align-items-center">
                        <div className="col-lg-6">
                          {/* Improving Image Start */}
                          <div className="improving-img">
                            <figure className="image-anime ">
                              <img src="assets/images/slider-1-b.jpg" alt="" />
                            </figure>
                          </div>
                          {/* Improving Image End */}
                        </div>
                        <div className="col-lg-6">
                          {/* Improving Content Start */}
                          <div className="improving-content">
                            {/* Section Title Start */}
                            <div className="section-title">
                              <h2 className="">Innovative Approach</h2>
                            </div>
                            {/* Section Title End */}
                            {/* Improving Body Start */}
                            <div className="improving-body">
                              <p className="">At SAGE Academy, we embrace innovation by integrating advanced technology and teaching methods into our curriculum. Our hands-on training and interactive online learning prepare students to excel in a rapidly evolving profession.</p>
                            </div>
                            {/* Improving Body End */}

                          </div>
                          {/* Improving Content End */}
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="we-improving">
                    <div className="container">
                      <div className="row align-items-center">
                        <div className="col-lg-6">
                          {/* Improving Image Start */}
                          <div className="improving-img">
                            <figure className="image-anime ">
                              <img src="assets/images/photo1720793341.jpeg" alt="" />
                            </figure>
                          </div>
                          {/* Improving Image End */}
                        </div>
                        <div className="col-lg-6">
                          {/* Improving Content Start */}
                          <div className="improving-content">
                            {/* Section Title Start */}
                            <div className="section-title">
                              <h2 className="">Flexibility and Convenience</h2>
                            </div>
                            {/* Section Title End */}
                            {/* Improving Body Start */}
                            <div className="improving-body">
                              <p className="">We know flexibility is key for busy professionals balancing work and personal commitments. That's why we offer customizable course schedules, including in-person, online, and hybrid options, to fit your individual needs and lifestyle.</p>
                            </div>
                            {/* Improving Body End */}

                          </div>
                          {/* Improving Content End */}
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="we-improving">
                    <div className="container">
                      <div className="row align-items-center">
                        <div className="col-lg-6">
                          {/* Improving Image Start */}
                          <div className="improving-img">
                            <figure className="image-anime ">
                              <img src="assets/images/compreh.png" alt="" />
                            </figure>
                          </div>
                          {/* Improving Image End */}
                        </div>
                        <div className="col-lg-6">
                          {/* Improving Content Start */}
                          <div className="improving-content">
                            {/* Section Title Start */}
                            <div className="section-title">
                              <h2 className="">Comprehensive Support</h2>
                            </div>
                            {/* Section Title End */}
                            {/* Improving Body Start */}
                            <div className="improving-body">
                              <p className="">At SAGE Academy, we support success beyond the classroom with personalized advising, career counseling, and job placement assistance, helping our graduates achieve their professional goals.</p>
                            </div>
                            {/* Improving Body End */}

                          </div>
                          {/* Improving Content End */}
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="we-improving">
                    <div className="container">
                      <div className="row align-items-center">
                        <div className="col-lg-6">
                          {/* Improving Image Start */}
                          <div className="improving-img">
                            <figure className="image-anime ">
                              <img src="assets/images/community.jpeg" alt="" />
                            </figure>
                          </div>
                          {/* Improving Image End */}
                        </div>
                        <div className="col-lg-6">
                          {/* Improving Content Start */}
                          <div className="improving-content">
                            {/* Section Title Start */}
                            <div className="section-title">
                              <h2 className="">Community and Networking</h2>
                            </div>
                            {/* Section Title End */}
                            {/* Improving Body Start */}
                            <div className="improving-body">
                              <p className="">Joining SAGE Academy connects you with a global network of professionals dedicated to excellence and collaboration. Our alumni offer valuable networking, mentorship, and career support, ensuring a supportive community for both new graduates and seasoned professionals.</p>
                            </div>
                            {/* Improving Body End */}

                          </div>
                          {/* Improving Content End */}
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="we-improving">
                    <div className="container">
                      <div className="row align-items-center">
                        <div className="col-lg-6">
                          {/* Improving Image Start */}
                          <div className="improving-img">
                            <figure className="image-anime ">
                              <img src="assets/images/why-choose-1.JPG" alt="" />
                            </figure>
                          </div>
                          {/* Improving Image End */}
                        </div>
                        <div className="col-lg-6">
                          {/* Improving Content Start */}
                          <div className="improving-content">
                            {/* Section Title Start */}
                            <div className="section-title">
                              <h2 className="">Commitment to Continuous Improvement</h2>
                            </div>
                            {/* Section Title End */}
                            {/* Improving Body Start */}
                            <div className="improving-body">
                              <p className="">At SAGE Academy, we prioritize continuous improvement and innovation. We regularly update our curriculum to incorporate the latest advancements in dentistry, ensuring you receive the most current and impactful education and training available.</p>
                            </div>
                            {/* Improving Body End */}

                          </div>
                          {/* Improving Content End */}
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
              {/* Our Projects Layout 2 End */}
            </div>
          </div>
        </div>
      </div>
      {/* Testimonial Section End */}
    </>

  )
}

export default WhyChooseUs
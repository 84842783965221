import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import "animate.css/animate.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import WOW from "wowjs";
import App from "./app/App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));

const paypalScriptOptions = {
  // chintant Account
  // "client-id": "ARW52ooZTRLyY1bG5gAYqfA6-pt2R5ZYtm5PWBPqUwnfiiljq81qQNFOsq5epS64IVujc8Y0e2s1cKyP",
  // "client Sandbox Account"
  "client-id": process.env.REACT_APP_PAYPAL_CLIENTID,
  currency: process.env.REACT_APP_PAYPAL_CURRENCY,
};

const wow = new WOW.WOW({ live: false });
wow.init();

root.render(
  <BrowserRouter>
    <PayPalScriptProvider options={paypalScriptOptions}>
      <App />
    </PayPalScriptProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import "owl.carousel/dist/assets/owl.carousel.css"; // Import the Owl Carousel CSS
import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import WOW from "wowjs";
import Home from "../components/userLayout/Home/Home";
import Layout from "../components/userLayout/Layout";
import OurCourses from "../components/userLayout/OurCourses/OurCourses";
import CourseDetails from "../components/userLayout/CourseDetails/CourseDetails";
import Login from "../components/userLayout/Login/Login";
import Signup from "../components/userLayout/Register/Register";
import { ToastContainer } from "react-toastify";
import PrivateRoute from "../guard/PrivateRoutes";
import ForgotPassword from "../components/userLayout/Login/ForgotPassword";
import PrivacyPolicy from "../components/userLayout/PrivacyPolicy/PrivacyPolicy";
import ReflectivePractice from "../components/commonPages/ReflectivePractice";
import {
  getCurrentUserFromLocalStorage,
  setCurrentUser,
} from "../localStorage/setCurrentUser";
import { getRequest, postRequest } from "../helper/axiosClient";
import { GET_ME } from "../components/constant/apiEndPoint";
import { getCurrentUser } from "../localStorage/getCurrentUser";
import BlogDetails from "../components/userLayout/Home/BlogDetails";
import PaymentSuccess from "../components/userLayout/OurCourses/PaymentSuccess";

function App() {
  useEffect(() => {
    new WOW.WOW({
      boxClass: "wow",
      animateClass: "animated",
      mobile: false,
      offset: 0,
      live: true,
      callback: function (box) {
        // the callback is fired every time an animation is started
        // the argument that is passed in is the DOM node being animated
      },
      scrollContainer: null,
    }).init();
  }, []);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const getUser = getCurrentUser();
    if (getUser) {
      (async () => {
        const data = await getRequest(GET_ME).then((res) => {
          setCurrentUser(res?.data?.data);
        });
      })();
    }
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route
            path="/login"
            element={
              <PrivateRoute>
                <Login />
              </PrivateRoute>
            }
          />
          <Route
            path="/signup"
            element={
              <PrivateRoute>
                <Signup />
              </PrivateRoute>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <PrivateRoute>
                <ForgotPassword />
              </PrivateRoute>
            }
          />
          <Route path="/our-courses" element={<OurCourses />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/courses-details" element={<CourseDetails />} />
          <Route path="/blog-details" element={<BlogDetails />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/reflectivepractice" element={<ReflectivePractice />} />
        </Route>
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;

import gsap from "gsap";
import React, { useEffect, useRef } from "react";
import SplitType from "split-type";
import { useNavigate } from "react-router-dom";
import "./home.css";
import WOW from "wowjs";
import moment from "moment";

function Servicediffrent({ courseList }) {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/our-courses");
  };

  useEffect(() => {
    new WOW.WOW().init();
  }, [courseList]);

  const elementRef = useRef(null);

  useEffect(() => {
    if (elementRef.current) {
      const element = elementRef.current;

      if (element.animation) {
        element.animation.progress(1).kill();
      }

      let split = new SplitType(element, { types: "lines,words,chars" });
      gsap.set(element, { perspective: 400 });

      gsap.set(split.chars, {
        opacity: 0,
        x: "50",
      });

      element.animation = gsap.to(split.chars, {
        scrollTrigger: { trigger: element, start: "top 90%" },
        x: "0",
        y: "0",
        rotateX: "0",
        opacity: 1,
        duration: 1,
        ease: "back.out",
        stagger: 0.02,
      });
    }

  }, [courseList]);

  return (
    <>
      {/* Medical Service Section Start */}
      <div id="course" className="medical-services recent-post courselist">
        <div className="blog-area ptb-150">
          <div className="container">
            <div className="row justify-content-center" data-cues="slideInUp">
              <div className="col-xl-4 col-lg-4 col-md-12">
                {/* Section Title Start */}
                <div className="medical-service-heading mb-md-4">
                  <div className="section-title">
                    <h3 className="wow fadeInUp">Our Course</h3>
                    <h2 className="text-anime-style-3" ref={elementRef}>
                      We're Providing Best Courses.
                    </h2>
                  </div>
                </div>
                {/* Section Title End */}
              </div>
              {/* {courseList.filter((data) => (data?.activeStatus === "live" && data?.isAvailable && new Date().toISOString().split('T')[0] <= new Date(data?.courseEndDate).toISOString().split('T')[0])).length > 0 ? ( */}
              {courseList.filter((data) => (data?.isAvailable && (new Date().toISOString().split('T')[0] <= new Date(data?.courseEndDate).toISOString().split('T')[0]) && (new Date().toISOString().split('T')[0] >= new Date(data?.courseStartDate).toISOString().split('T')[0]))).length > 0 ? (
                <>
                  {courseList.filter((data) => (data?.isAvailable && (new Date().toISOString().split('T')[0] <= new Date(data?.courseEndDate).toISOString().split('T')[0]) && (new Date().toISOString().split('T')[0] >= new Date(data?.courseStartDate).toISOString().split('T')[0]))).slice(0, 5).map((item, i) => (
                    <div
                      className="col-xl-4 col-lg-4 col-md-6"
                      key={i}
                      onClick={() =>
                        navigate(`/courses-details?id=${item._id}`)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div
                        className="blog-card extra-gap post-item wow fadeInUp"
                        data-wow-delay="0.25s"
                      >
                        <div className="p-4 servicebordercladd">
                          <div className="blog-image">
                            <span className="post-featured-image">
                              <figure className="image-anime serviceblog">
                                {item?.courseImg && item?.courseImg[0] && (
                                  <img
                                    alt={item?.courseImg[0]}
                                    src={
                                      process.env.REACT_APP_IMAGE_API_URL +
                                      item?.courseImg[0]
                                    }
                                  />
                                )}
                              </figure>
                            </span>
                            <div className="user">
                              <figure className="image-anime">
                                <img
                                  alt={item.speakerImg[0]}
                                  src={
                                    process.env.REACT_APP_IMAGE_API_URL +
                                    item.speakerImg[0]
                                  }
                                />
                              </figure>
                            </div>
                          </div>
                          <div
                            className="blog-content"
                            style={{ backgroundColor: "transparent" }}
                          >
                            <span className="tag-btn">
                              {item?.category?.name}
                            </span>
                            <div className="post-item-body">
                              <h2>{item?.name}</h2>
                              {/* <div className="text-truncate">
                                  This advanced course is designed for dental
                                  professionals who aspire to excel in the field
                                  of aesthetic dentistry. It combines
                                  theoretical knowledge with practical skills,
                                  enabling participants to deliver high
                                </div> */}
                              {/* <p
                                  dangerouslySetInnerHTML={{
                                    __html: item?.description,
                                  }}
                                /> */}
                              <ul className="list mt-3 mb-0">
                                <li className="dateli">
                                  <strong>
                                    <i className="fa fa-calendar-days"></i>
                                    &nbsp;{moment(item?.courseStartDate).format('DD-MM-YYYY')} to{" "}
                                    {moment(item?.courseEndDate).format('DD-MM-YYYY')}
                                  </strong>
                                </li>
                                <li className="priceli">
                                  Price :
                                  <strong>
                                    {+item?.price === 0 ? "Free" : "Paid"}
                                  </strong>
                                </li>
                              </ul>
                              <span
                                className="btn-defaultnew learnbutton wow fadeInUp col-xl-6 col-md-6 p-2"
                                data-wow-delay="0.5s"
                              >
                                Learn more
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="w-full">
                    <div className="d-flex justify-content-center">
                      <button
                        type="submit"
                        className="btn-default"
                        onClick={() => handleRedirect()}
                      >
                        See More
                      </button>
                      <div id="msgSubmit" className="h3 hidden" />
                    </div>
                  </div>
                </>
              ) : (
                <p className="d-flex justify-content-center">
                  No courses available at the moment. Please check back soon for
                  new opportunities to learn and grow!
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Medical Service Section End */}
    </>
  );
}

export default Servicediffrent;

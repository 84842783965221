import React, { useEffect, useState } from 'react'
import Header from '../../commonPages/Header'
import Banner from './Banner'
import Footer from '../../commonPages/Footer'
import { Link } from 'react-router-dom'
import { errorNotification, successNotification } from '../../../helper/notification'
import { setCurrentUser } from '../../../localStorage/setCurrentUser'
import { CREATE_STUDENT } from '../../constant/apiEndPoint'
import { postRequest } from '../../../helper/axiosClient'
import { Spinner } from 'react-bootstrap'

const PaymentSuccess = () => {
  const payValues = JSON.parse(localStorage.getItem("payValues"));
  const [loader, setLoader] = useState(true);

  const getQueryParams = (search) => {
    const params = new URLSearchParams(search);
    const queryParams = {};
    for (let param of params.entries()) {
      queryParams[param[0]] = param[1];
    }
    return queryParams;
  };

  const params = getQueryParams(window?.location?.search);

  const createStudent = async () => {
    try {
      const payload = payValues;
      const res = await postRequest(CREATE_STUDENT, payload);

      if (res.type === 1) {
        setLoader(false);
        localStorage.removeItem("payValues");
        successNotification(res?.response?.data?.message);
        setCurrentUser(res?.response?.data?.user);
      }
      if (res.type === 0) {
        errorNotification(res.errormessage);
      }
    } catch (error) {
      console.error("res====>", error);
    }
  };

  useEffect(() => {
    if (payValues) {
      createStudent();
    }
  }, [])

  return (
    <>
      {/* Main content Start */}
      <div className="main-content">
        <Header />
        <Banner loader={loader} />
        {loader ?
          <div className='text-center py-5 my-5'>
            <Spinner animation="grow" style={{ background: "#F0BD6C" }} />
            <p className='mt-3' style={{ color: "#F0BD6C" }}>Please Wait Until Student Creates...</p>
          </div>
          :
          <div className='container py-5'>
            <div className='paymentDiv'>
              <h3>Thank You For Purchasing!</h3>

              <ul className='my-4'>
                <li><span>Payment ID</span> {params?.paymentId}</li>
                <li><span>Token</span> {params?.token}</li>
                <li><span>Payer ID</span> {params?.PayerID}</li>
              </ul>

              <Link to='/our-courses' className='btn-default'>Back to courses</Link>
            </div>
          </div>
        }

      </div>

      <Footer />
      {/* Main content End */}
    </>
  )
}

export default PaymentSuccess
import React, { useEffect, useState } from "react";
import Header from "../../commonPages/Header";
import Banner from "./Banner";
import Servicediffrent from "./Servicediffrent";
import Footer from "../../commonPages/Footer";
import { getRequest } from "../../../helper/axiosClient";
import { COURSE_DETAILS } from "../../constant/apiEndPoint";
import { useSearchParams } from "react-router-dom";
import { errorNotification } from "../../../helper/notification";
import { getCurrentUserFromLocalStorage } from "../../../localStorage/setCurrentUser";

const CourseDetails = () => {
  const [courseDetails, setCourseDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const couresId = searchParams.get("id");

  const getCourseDetails = async (Id) => {
    try {
      const params = { id: Id };
      const res = await getRequest(COURSE_DETAILS, params);
      if (res?.data?.status === 1) {
        setCourseDetails(res?.data?.data[0]);
      }
      if (res.type === 2) {
        errorNotification(res.errormessage);
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (couresId) {
      setLoading(true);
      getCourseDetails(couresId);
    }
  }, []);

  return (
    <>
      {/* Main content Start */}
      <div className="main-content">
        <Header />
        <Banner courseDetails={courseDetails} />
        <Servicediffrent loading={loading} courseDetails={courseDetails} />
      </div>
      <Footer />
      {/* Main content End */}
    </>
  );
};

export default CourseDetails;
